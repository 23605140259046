import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { DARK_BLUE, WRAPPER_WIDTH, TABLET_MIN_WIDTH } from './config'
import PrimaryButton from './PrimaryButton'

const Feedback = () => {
  return (
    <OuterWrapper>
      <Wrapper>
        <Box>
          <Headline>
            Du hast Feedback zur App oder möchtest Kontakt mit uns
            aufnehmen?
          </Headline>
          <Text>Wir freuen uns von dir zu hören!</Text>
          <PrimaryButton href='mailto:hallo@heypixie.com'>
            hallo@heypixie.com
          </PrimaryButton>
        </Box>
      </Wrapper>
    </OuterWrapper>
  )
}

const OuterWrapper = styled.section`
  background-color: ${DARK_BLUE};
  color: white;
`

const Wrapper = styled.div`
  max-width: ${WRAPPER_WIDTH}px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 80px 32px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    padding: 32px 24px;
  }
`

const Box = styled.div`
  max-width: 560px;
  text-align: center;
`

const Headline = styled.h2`
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  margin-bottom: 32px;

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    font-size: 20px;
    line-height: 28px;
  }
`

const Text = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;
`

export default Feedback
